import Menu from "./Menu";

export default function SiteHeader({switchLanding}) {
    return (
        <header className='sticky top-0 z-10 w-full'>
            <div className='h-24 content flex justify-between items-center'>
                <button onClick={switchLanding} className='divGradient p-4 rounded-2xl'>
                    <span className='sm:text-2xl text-white font-bold font-sans'>3Dot</span>
                    <span className='sm:text-2xl text-yellow-400 font-bold font-sans'>Studio</span>
                </button>
                <Menu top={false} color='text-white' />
            </div>


        </header>
    );
}