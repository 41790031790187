import Menu from "./Menu";

export default function Footer() {
    return (
        <footer className='w-full text-white'>
            <div className='hidden flex justify-between items-center'>
                <div className='divGradient p-4 rounded-2xl'>
                    <span className='sm:text-2xl text-white font-bold font-sans'>3Dot</span>
                    <span className='sm:text-2xl text-yellow-400 font-bold font-sans'>Studio</span>
                </div>
                <Menu top={true} color='text-white' />
            </div>
            <div className='flex justify-center py-10'>
                <span>2023©3DotStudio</span>
            </div>
        </footer>
    );
}