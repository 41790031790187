export default function ProjectBlock(props) {
    return (
        <div className='sm:h-110 sm:w-120 divGradient rounded-2xl mt-6 sm:mr-6'>
            {
                props.video ?
                    <video className='sm:h-64 sm:w-120 rounded-2xl'
                           src={props.video}
                           type="video/mp4"
                           poster={props.picture}
                           controls/>
                    : <img className='h-64 sm:w-120 rounded-2xl' src={props.picture} alt=''/>
            }
            <div className='p-6'>
                <div className='font-bold'>{props.title}</div>
                <div>{props.technologies}</div>
            </div>
        </div>
    );
}