import SimulationMaterial from './shaders/simulation/simulationMaterial';

import * as THREE from "three";
import {Canvas, extend} from "@react-three/fiber";
import {useRef} from "react";
import {Landing} from "./components/Landing";
import {FBOParticles} from "./shaders/FBOParticles";


extend({SimulationMaterial: SimulationMaterial});

export default function App() {
    const mouseRef = useRef({x: 0, y: 0});

    return (
        <div className='min-w-full min-h-full' onPointerMove={(e) => {
            mouseRef.current.x = (e.clientX / window.innerWidth) * 2 - 1;
            mouseRef.current.y = -(e.clientY / window.innerHeight) * 2 + 1;
        }}>
            <Canvas
                gl={{antialias: true, toneMapping: THREE.NoToneMapping}}
                linear
                camera={{position: [6, 0, 0]}}
                style={{position: "fixed", top: 0, left: 0, zIndex: -1, background: 'black'}}>
                <FBOParticles mouseRef={mouseRef}/>
                <ambientLight intensity={0.5}/>
            </Canvas>
            <Landing/>
        </div>

    );
}


