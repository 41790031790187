import {useState, useEffect} from "react";
import SiteHeader from "./SiteHeader";
import Main from "./Main";

export function Landing() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 1500);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const switchLanding = () => {
        setIsVisible(!isVisible);
    };
    return (
        <div className='block w-3/4 m-auto'>
            <SiteHeader switchLanding={switchLanding}/>
            <Main isVisible={isVisible}/>

        </div>
    );
}