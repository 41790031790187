import glslCurlNoise from "./glslCurlNoise";

const fragmentShader = `

uniform sampler2D positions;
uniform float uTime;
uniform float uFrequency;
uniform vec2 uMouse;

varying vec2 vUv;

${glslCurlNoise}
void main() {
  vec3 pos = texture2D(positions, vUv).xyz;

  pos += curlNoise(pos * uFrequency + uTime * 0.1)/100.0;
  //pos += curlNoise(pos * uFrequency * 2.0 + uTime * 0.05) * 0.005;

  if (uMouse.x >= -1.0) { 
    vec2 mPos = uMouse * 3.0;
    mPos.x *= -1.0;

    vec2 toMouse = mPos - pos.zy;
    float distance = length(toMouse);
    vec2 force = normalize(toMouse) * (1.0 / distance);
    pos.zy += force * 0.01; // adjust the 0.01 value to control the strength of the force
  }

  float limitX = 6.0;
  pos.x = pos.x + 0.01; // move x forward
  //pos.x = mod(pos.x, limitX); // clamp and restart
  if (pos.x > limitX) {
    pos = normalize(pos) * limitX;
    pos.x = 0.0;
  }

  vec3 spherePosition = vec3(1.0, 0.0, 0.0);
  vec3 toSphere = pos - spherePosition;
  if (length(toSphere) < 2.0) {
    pos = spherePosition + normalize(toSphere) * 2.0;
  }

  gl_FragColor = vec4(pos, 1.0);
}
`;

export default fragmentShader;