const vertexShader = `
uniform sampler2D uPositions;
uniform float uTime;
uniform vec2 uMouse;
varying vec2 vMouse;
varying float vDistance;
varying vec3 vProj;
varying vec3 vOriginalPosition;

void main() {
  vMouse = uMouse;
  vec3 pos = texture2D(uPositions, position.xy).xyz;
  vOriginalPosition = pos;

  vec4 modelPosition = modelMatrix * vec4(pos, 1.0);
  vec4 viewPosition = viewMatrix * modelPosition;
  vec4 projectedPosition = projectionMatrix * viewPosition;

  gl_Position = projectedPosition;
  vProj = projectedPosition.xyz;

  gl_PointSize = mix(4.0, 9.0, pos.x / 2.0);
  // Size attenuation;
  gl_PointSize *= step(1.0 - (1.0/64.0), position.x) + 0.5;

  float distanceFactor = pow(gl_PointSize - distance(position, vec3(0.0)), 1.5);
  // float size = distanceFactor * 10.0 + 10.0;
  // vec3 particlePosition = position * rotation3dY(uTime * 0.3 * distanceFactor);

  vDistance = pos.x / 5.0;
}

`;

export default vertexShader;