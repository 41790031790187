export const projects = [
    {
        title: 'MSA Basketball 2D',
        picture: 'images/MSA2D.png',
        video: 'videos/msa2d.mp4',
        technologies: 'XREngine, Three.js, Ecs, React'
    },
    {
        title: 'MSA Basketball 3D',
        picture: 'images/MSA3D.png',
        video: 'videos/msa3d.mp4',
        technologies: 'Three.js'
    },
    {
        title: 'ARC',
        picture: 'images/ARC.png',
        video: 'videos/arc.mp4',
        technologies: 'Vuforia, Capacitor'
    },
    {
        title: 'Wild Capture Agency',
        picture: 'images/WildCaptureAgency.png',
        technologies: 'WebGL, Three.js, React, Node.js'
    },
    {
        title: 'Lego education platform',
        picture: 'images/Lego.png',
        technologies: 'PHP, Javascript, Three.js'
    },
    {
        title: 'Light Co-Lab',
        picture: 'images/LightCo-Lab.png',
        technologies: 'WebGL, Three.js, Vue.js, PHP'
    }
];


