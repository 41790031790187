const fragmentShader = `
varying vec2 vMouse;
varying float vDistance;
varying vec3 vProj;
varying vec3 vOriginalPosition;

vec3 hsl2rgb( in vec3 c )
{
    vec3 rgb = clamp( abs(mod(c.x*6.0+vec3(0.0,4.0,2.0),6.0)-3.0)-1.0, 0.0, 1.0 );

    return c.z + c.y * (rgb-0.5)*(1.0-abs(2.0*c.z-1.0));
}

vec3 HueShift (in vec3 Color, in float Shift)
{
    vec3 P = vec3(0.55735)*dot(vec3(0.55735),Color);
    
    vec3 U = Color-P;
    
    vec3 V = cross(vec3(0.55735),U);    

    Color = U*cos(Shift*6.2832) + V*sin(Shift*6.2832) + P;
    
    return vec3(Color);
}

void main() {
  // glow
  float strength = distance(gl_PointCoord, vec2(0.5));
  strength = 1.0 - strength;
  strength = pow(strength, 4.0);
  // glow

  vec3 color = abs(sin(vOriginalPosition)/2.0+0.5);
  vec3 colorEnd = HueShift(color, 180.0);
  vec3 mouseColor = vec3(0.0, abs(vMouse.x), 0.0);

  color = mix(color, colorEnd, vOriginalPosition.x/10.0);

  gl_FragColor = vec4(color, strength);
}
`;

export default fragmentShader;
