import Reviews from '../contents/Reviews.png';
import Contact from '../contents/ContactUs.png'

import {projects} from "../constants";
import ContactUs from "./ContactUs";
import ProjectBlock from "./ProjectBlock";

import Footer from "./Footer";

export default function Main({isVisible}) {
    return (
        <div className={`fade-in-out-element ${isVisible ? 'opacity-100 pointer-events-auto' 
            : 'opacity-0 pointer-events-none'} content text-white origin-top`}>
            <div className='xl:h-screen sm:p-12 p-6 w-full items-center relative rounded-2xl divGradient'>
                <div className='xl:w-full xl:pt-28 flex flex-wrap xl:flex-col justify-center lg:justify-start space-y-10'>
                    <h1>
                        <span>We will help create</span>
                        <div>
                            <span className='text-yellow-400'> New Reality </span>
                            for<span className='text-yellow-400'> you </span>
                        </div>
                    </h1>
                    <div className='text-center lg:text-start flex flex-wrap justify-center lg:justify-start'>
                        <span className='w-full sm:text-2xl font-sans'>
                            By providing long-term out-staff solutions.
                        </span>
                        <span className='sm:text-2xl font-sans'>
                            Our passion: 3D web applications.
                        </span>
                    </div>
                    <div className='h-14 sm:w-64 w-48 '>
                        <a  href={'#contacts'}>
                            <button className='h-14 sm:w-64 w-48 rounded-2xl bg-yellow-400 shadow-xl'>
                                <span className='text-black font-bold font-sans'>Start a project</span>
                            </button>
                        </a>
                    </div>

                </div>
                {/*<div className='hidden w-full lg:flex justify-end'>*/}
                {/*    <img alt='' src={WelCome}/>*/}
                {/*</div>*/}
            </div>

            <div id='about_us'
                 className='md:px-12 px-4 py-4 md:py-28  w-full items-center relative rounded-2xl divGradient'>
                <div className='lg:w-2/3 flex flex-wrap space-y-10 justify-center lg:justify-start'>
                    <h1 >
                        <div>
                            Get to <span className='text-yellow-400'>Know Us</span>
                        </div>
                        <div>
                            We have <span className='text-yellow-400'>No Borders</span>
                        </div>
                    </h1>
                    <div className='text-center lg:text-start space-y-10'>
                        <div className='md:text-xl font-sans'>
                            From all over the world, our clients have been pleased with great project
                            results in web application development, 3D chart projects,
                            and social media network creations.
                            Our team consists of one tech lead, one HR manager, a PM, and a goal-oriented dev. team.
                            experienced in different fields.
                        </div>
                        <div className='md:text-xl font-sans'>
                            Our expertise is webGL, three.js, react, typescript, node.js,
                            Java for Android, swift for IOS,
                            and capacitor for both mobile platforms.
                            Recently we have been learning Unreal Engine, and C++ and soon will be expert-level.
                        </div>
                        <div className='md:text-xl font-sans'>
                            We believe in human connection and more than finalizing a project,
                            we want to add value to all the deliveries.
                            Teamwork and ownership over client projects,
                            allow us to offer comprehensive and non-standard solutions for your needs!
                        </div>
                        <div className='md:text-xl font-sans'>
                            With our size and values,
                            we can easily manage to deliver exactly the value that the clients want,
                            much faster and more adaptable than bigger companies.
                        </div>
                    </div>
                </div>
                {/*<div className='-mt-48 hidden w-full lg:flex justify-end'>*/}
                {/*    <img alt='' src={OurTeam}/>*/}
                {/*</div>*/}
            </div>

            <div
                className='w-full flex flex-wrap lg:flex-col xl:flex-row space-y-4 xl:space-y-0 mt-12 justify-between'>
                <div className='text-center xl:h-110 xl:w-1/3 py-10 px-6 rounded-2xl divGradient'>
                    <h2 className='font-bold text-xl sm:text-2xl'>
                        Our vision:
                    </h2>
                    <div className='mt-4 md:text-xl'>
                        To provide successful partnerships,
                        being able to give people accessible
                        and high-quality communication in web and mobile applications.
                    </div>
                </div>
                <div className='text-center xl:h-110 xl:w-1/3 py-10 px-6 rounded-2xl divGradient'>
                    <h2 className='font-bold text-xl sm:text-2xl'>
                        Our mission:
                    </h2>
                    <div className='mt-4 md:text-xl'>
                        For clients, for employees, for investors and partners:
                        our mission is to make people happier while
                        using our products or working with the company.
                    </div>
                </div>
                <div className='text-center xl:h-110 xl:w-1/3 py-10 px-6 rounded-2xl divGradient'>
                    <h2 className='font-bold text-xl sm:text-2xl'>
                        Our values:
                    </h2>
                    <div className='mt-4 md:text-xl'>
                        Collaboration and support are what allow us to grow
                        The client-Centric approach is what differentiates us
                        Continuous Learning is a must
                        Effective Communication. Thats it!
                    </div>
                </div>
            </div>

            <div id='projects' className='mt-32'>
                <h1 >Check
                    our most relevant <span className='text-yellow-400'>Projects</span></h1>
                <div className='flex flex-wrap justify-center 2xl:justify-start'>
                    {projects.map(
                        (item, index) =>
                            <ProjectBlock
                                key={index}
                                picture={item.picture}
                                video={item.video}
                                technologies={item.technologies}
                                title={item.title}
                            />
                    )}
                </div>
            </div>

            <div id='clients_opinion' className='w-full sm:mt-16 sm:mb-16 divSize sm:relative'>
                <div className='h-full w-full'>
                    <img alt='' className='hidden sm:flex' src={Reviews}/>
                    <div className='sm:absolute sm:top-10 sm:w-full 2xl:space-y-12'>
                        <h1 >
                            Clients <span className='text-yellow-400'>Opinion</span>
                        </h1>
                        <div
                            className='w-full mb-6 text-center flex flex-wrap lg:flex-nowrap items-center divGradient rounded-2xl lg:space-x-6 p-6'>
                            <img alt='' className='rounded-xl h-48 w-full sm:h-full mb-4 lg:mb-0 lg:w-1/3'
                                 src='images/Lego.png'/>
                            <div
                                className='xl:w-2/3 w-full flex flex-wrap justify-center lg:justify-start text-center lg:text-start'>
                                <div>
                                    <h2 className='font-bold xl:text-2xl'>SIRRI ONALLAH, ISRAEL</h2>
                                    <span className='text-yellow-200 xl:text-xl'>Lego education platform</span>
                                </div>
                                <span className='xl:text-xl'>
                                "Our contact was based on relationships
                                and I think that is why we succeed. Usually,
                                when we approach other companies they are afraid of personal
                                relationships and they want to do things their own way.
                                With them was different, they could accept my knowledge,
                                and we decided together that I could manage the project myself."
                            </span>
                            </div>
                        </div>

                        <div
                            className='w-full mb-6 text-center flex flex-wrap lg:flex-nowrap items-center divGradient rounded-2xl lg:space-x-6 p-6'>
                            <img className='rounded-xl h-48 w-full sm:h-full mb-4 lg:mb-0 lg:w-1/3 xl:h-48' alt=''
                                 src='images/The_world__I.png'/>
                            <div
                                className='xl:w-2/3 w-full flex flex-wrap justify-center lg:justify-start text-center lg:text-start'>
                                <div>
                                    <h2 className='font-bold sm:text-2xl'>GEORGE FUENTES, CALIFORNIA</h2>
                                    <span className='text-yellow-200 sm:text-xl'>The world and I</span>
                                </div>
                                <span className='xl:text-xl'>
                                "The cooperation was good.
                                The team was very clear on the initial directions and
                                I immediately had a good impression. During all the process,
                                all the technical aspects were very much surrounded by my proposal on the applications.
                                This way. we could meet the requirement as long as I felt comfortable with the result."
                            </span>
                            </div>
                        </div>
                        <div
                            className='w-full mb-6 text-center flex flex-wrap lg:flex-nowrap items-center divGradient rounded-2xl lg:space-x-6 p-6'>
                            <img alt='' className='rounded-xl h-48 w-full sm:h-full mb-4 lg:mb-0 lg:w-1/3'
                                 src='images/WildCaptureAgency.png'/>
                            <div
                                className='xl:w-2/3 w-full flex flex-wrap justify-center lg:justify-start text-center lg:text-start'>
                                <div>
                                    <h2 className='font-bold sm:text-2xl'>WILL, USA.</h2>
                                    <span className='text-yellow-200 sm:text-xl'>Wildcapture</span>
                                </div>
                                <span className='xl:text-xl'>
                                    "This company is made by a friendly and hardworking team.
                                    Nobody has ever done a project similar to what we are doing,
                                    and even in critical tasks, we understand each other quickly!"
                                </span>
                            </div>
                        </div>

                        <div id='contacts' className='flex flex-col w-full divGradient rounded-2xl'>
                            <img alt='' className='hidden sm:flex' src={Contact}/>
                            <ContactUs/>
                        </div>
                        <Footer/>                    </div>
                </div>

            </div>
        </div>
    );
}