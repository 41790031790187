import React, {useState} from 'react';

const items = [
    {title: 'Projects', ref: '#projects'},
    {title: 'Clients Opinion', ref: '#clients_opinion'},
    {title: 'About us', ref: '#about_us'},
    {title: 'Contacts', ref: '#contacts'},
];

export default function Menu(props) {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div className={`${props.color} hidden 2xl:flex space-x-10 divGradient p-4 rounded-2xl`}>
                {items.map((item, index) =>
                    <a key={index} href={item.ref}>
                        <button className='text-base font-bold'>{item.title}</button>
                    </a>
                )}
            </div>
            <div className='relative 2xl:hidden'>
                {props.top === true ? isOpen && (
                    <div className='absolute p-6 rounded-2xl font-bold text-white w-24 z-10 divGradient bottom-14'>
                        {items.map((item, index) =>
                            <a key={index} href={item.ref}>
                                <button className='py-4 text-sm text-base font-bold'>{item.title}</button>
                            </a>
                        )}
                    </div>
                ) : <></>}
                <button className='py-4 px-7 bg-yellow-400 text-black font-bold rounded-2xl relative z-10'
                        onClick={handleToggle}>
                    Menu
                </button>
                {props.top === false ? isOpen && (
                    <div className='absolute p-6 rounded-2xl font-bold text-white w-24 z-10 divGradient'>
                        {items.map((item, index) =>
                            <a key={index} href={item.ref}>
                                <button className='py-4 text-sm text-base font-bold'>{item.title}</button>
                            </a>
                        )}
                    </div>
                ) : <></>}
            </div>
        </div>

    );
}