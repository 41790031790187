import simulationVertexShader from "./simulationVertexShader";
import simulationFragmentShader from "./simulationFragmentShader";
import * as THREE from "three";

export const getRandomData = (width, height) => {
    const length = width * height * 4;
    const data = new Float32Array(length);

    for (let i = 0; i < length; i++) {
        const stride = i * 4;

        const distance = Math.sqrt(Math.random()) * 2.0;
        const theta = THREE.MathUtils.randFloatSpread(360);
        const phi = THREE.MathUtils.randFloatSpread(360);

        data[stride] = distance * Math.sin(theta) * Math.cos(phi);
        data[stride + 1] = distance * Math.sin(theta) * Math.sin(phi);
        data[stride + 2] = distance * Math.cos(theta);
        data[stride + 3] = 1.0;
    }

    return data;
};

class SimulationMaterial extends THREE.ShaderMaterial {
    constructor(elapsedTime, positionsTexture) {
        const simulationUniforms = {
            positions: { value: positionsTexture }, // <!--- new
            uFrequency: { value: 0.25 },
            uTime: { value: elapsedTime }, // <!-- new
            uMouse: { value: { x: -1, y: -1 } }
        };

        super({
            uniforms: simulationUniforms,
            vertexShader: simulationVertexShader,
            fragmentShader: simulationFragmentShader
        });
    }
}

export default SimulationMaterial;